import React, { useEffect, useState } from 'react'
import { Loader } from '@mit/hui'
import { Region } from '../authorization'
import { LoaderProps } from '@mit/hui/build/components/Loader'
import { SessionExpirationModal } from './SessionExpirationModal'
import { EventBus } from '../common'
import { AppInitializationBody, AppConfig, RegionalConfig } from '../types'
import { WithRegionContext } from '../hoc'
import { useRegionContext } from '../context'

interface Props {
  App: React.FunctionComponent
  appInitializer: () => void
  appConfig: AppConfig
  loaderProps: LoaderProps
  useCognitoAuthentication: boolean
}

const InitializationImplementation: React.FC<Props> = ({ App, appInitializer, appConfig, loaderProps, useCognitoAuthentication }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasAccess, setHasAccess] = useState<boolean>(true)
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [regionConfig, setRegionConfig] = useState<RegionalConfig | null>(null)
  const { hasOutage, activeRegionInitialized, setHasOutage, setActiveRegionInitialized } = useRegionContext()

  console.log('setRegionConfig123', setRegionConfig)

  // Set Up Listeners For Global Events
  useEffect(() => {
    EventBus.subscribe('APP_INITIALIZED', ({ isLoading, hasAccess }: AppInitializationBody) => {
      console.error('APP INITIALAIZED')
      setIsLoading(isLoading)
      setHasAccess(hasAccess)
    })
    EventBus.subscribe('REGION_INITIALIZED', () => {
      console.error('REGION INITIALAIZED')
      setActiveRegionInitialized(true)
      appInitializer()
    })
    EventBus.subscribe('REGION_OUTAGE', () => setHasOutage(true))
    EventBus.subscribe('SESSION_EXPIRED', () => setSessionExpired(true))
    EventBus.subscribe('CHECK_REGION_UPDATE', () => console.log('CHECK_REGION_UPDATE'))

    return () => {
      EventBus.unsubscribe('APP_INITIALIZED')
      EventBus.unsubscribe('REGION_INITIALIZED')
      EventBus.unsubscribe('REGION_OUTAGE')
      EventBus.unsubscribe('SESSION_EXPIRED')
    }
  }, [])

  // Initialize Active Region
  useEffect(() => {
    const region = new Region(appConfig)
    region
      .initializeActiveRegion(useCognitoAuthentication)
      .then((_) => {})
      .catch((error) => {
        console.error(error)
        setIsLoading(false)
        setHasAccess(false)
      })

    // eslint-disable-next-line
  }, [])

  if (isLoading || !hasAccess || hasOutage || !activeRegionInitialized) {
    return (
      <>
        {activeRegionInitialized}
        <Loader
          {...loaderProps}
          hasAccess={hasAccess}
          isLoading={isLoading}
          type={hasOutage ? 'outage' : hasAccess || isLoading ? 'default' : 'noAccess'}
        />
      </>
    )
  }

  return (
    <>
      {sessionExpired && <SessionExpirationModal regionConfig={regionConfig as RegionalConfig} />}
      <App />
    </>
  )
}

export const Initialization = WithRegionContext(InitializationImplementation)
